
import { registration,paymentMethodLabel } from "@/store/stateless/store";
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
  },
  setup() {
    const store = useStore();

    const accountsDashboardData = computed(() => {
      return store.getters.getAccountsDashboardSummary;
    });

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const currency = (e) => {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: registration.currency
      });

      return formatter.format(e);
    };

    const roundValue = (value) => {
      return value.toFixed(2);
    }

    onMounted(() => {
      console.log(getTextWidth('148426.59 / 50000', ''))
    })

    const getTextWidth = (text, font) => {
      const canvas = document.createElement("canvas");
      const context: any = canvas.getContext("2d");

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    };

    return {
      registration,
      getTextWidth,
      roundValue,
      currency,
      accountsDashboardData,
      user,
      paymentMethodLabel
    };
  }
});
