import store from "@/store";
import router from "@/router";
import { Actions } from "@/store/enums/StoreEnums";
import { App, computed, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";

// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class DashboardService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        DashboardService.vueInstance = app;
    }

    public static getDashboardOverview(payload) {// 
        return new Promise<void>((resolve, reject) => {
          store
            .dispatch(Actions.GET_DASHBOARD_OVERVIEW,payload)
    
            .then((data) => { resolve(data); })
            .catch((error) => { reject(error); })
        })
      }


      public static async getSalesPerson(payload) {
        return await store.dispatch(Actions.GET_SALES_PERSON_INFO, payload);
      }

      public static async getStatistics(payload) {
        return await store.dispatch(Actions.GET_SALES_STATISTICS, payload);
      }

      public static async getOthers(payload) {
        return await store.dispatch(Actions.GET_SALES_OTHERS, payload);
      }

      public static async getSalesAgentManager(payload) {
        return await store.dispatch(Actions.GET_SALES_AGENT_MANAGER, payload);
      }

      public static async getSalesUnderwriter(payload) {
        return await store.dispatch(Actions.GET_SALES_UNDERWRITER, payload);
      }

      public static async getUserLogs(payload) {
        return await store.dispatch(Actions.GET_DASHBOARD_GET_USER_LOGS, payload);
      }

      public static async getOnline(payload) {
        return await store.dispatch(Actions.GET_DASHBOARD_ONLINE_TRANSACTION, payload);
      }

      public static async getDirect(payload) {
        return await store.dispatch(Actions.GET_DASHBOARD_DIRECT_TRANSACTION, payload);
      }

      public static async getCOD(payload) {
        return await store.dispatch(Actions.GET_DASHBOARD_COD_TRANSACTION, payload);
      }

      public static async getLeadsNew(payload) {
        return await store.dispatch(Actions.GET_DASHBOARD_LEADS_NEW, payload);
      }

      public static async getLeadsLost(payload) {
        return await store.dispatch(Actions.GET_DASHBOARD_LEADS_LOST, payload);
      }

      public static async getLeadsPending(payload) {
        return await store.dispatch(Actions.GET_DASHBOARD_LEADS_PENDING, payload);
      }

      public static async getAppLinks() {
        return await store.dispatch(Actions.GET_APPS);
      }

      public static async getMarketingDashboard(payload) {
        return await store.dispatch(Actions.GET_MARKETING_DASHBOARD, payload);
      }

      public static async getAccountsDashboard(payload) {
        return await store.dispatch(Actions.GET_ACCOUNTS_DASHBOARD, payload);
      }

}

export default DashboardService;
