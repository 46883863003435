<template>
  <div class="card">
    <div
      class="card-header boder-0 pt-5"
      :style="`background-color: ${backgroundColor} !important`"
    >
      <h3 class="card-title align-items-start flex-column" v-if="showTitle" >
        <span
          class="card-label fw-bolder fs-3 mb-1"
          :style="`color: ${fontColor} !important`"
        >
          {{ title }}
        </span>
      </h3>
    </div>
      <div class="card-body card-custom-spacing-tblf-10 bg-white" :class="charClass" v-if="series && series.length > 0">
                <div class="pt-0 p-0">
                    <div class="table-responsive table-simple-dash">

      <VueApexCharts
        type="pie"
        :width="chartOptions.chart.width"
        :height="chartOptions.chart.height"
        :options="chartOptions"
        :series="series"
      ></VueApexCharts>
    </div>
                </div>
            </div>
    <div class="card-body" v-else>
      <p v-if="!isLoading" class="text-center">No Records Available</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import VueApexCharts from "vue3-apexcharts";

export default defineComponent({
  props: {
    chartOptions: {},
    series: [],
    title: String,
    backgroundColor: {
      type: String,
      required: false,
      default: "#fff"
    },
    fontColor: {
      type: String,
      required: false,
      default: "#000"
    },
    charClass: {
      type: String,
      required: false,
      default: ""
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    VueApexCharts
  }
});
</script>
