import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container ps-2 pe-2" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 0,
  class: "col-sm-12 mb-6 p-1"
}
const _hoisted_4 = {
  key: 1,
  class: "col-sm-12 mb-6 p-1"
}
const _hoisted_5 = {
  key: 2,
  class: "col-sm-12 mb-6 p-1"
}
const _hoisted_6 = {
  key: 3,
  class: "col-sm-4 mb-6 p-1"
}
const _hoisted_7 = {
  key: 4,
  class: "col-sm-4 mb-6 p-1"
}
const _hoisted_8 = { class: "col-sm-6 mb-6 p-1" }
const _hoisted_9 = { class: "col-sm-6 mb-6 p-1" }
const _hoisted_10 = { class: "col-sm-6 mb-6 p-1" }
const _hoisted_11 = { class: "col-sm-6 mb-6 p-1" }
const _hoisted_12 = {
  key: 5,
  class: "col-sm-12 mb-6 p-1"
}
const _hoisted_13 = {
  key: 6,
  class: "col-sm-12 mb-6 p-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tableStats = _resolveComponent("tableStats")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.user.role_id != 7)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_tableStats, {
              columns: _ctx.topUserLogsColumns,
              items: _ctx.userLogs.data,
              title: "Users Logs",
              isPagination: "",
              paginationPageSize: _ctx.userLogs.per_page,
              paginationPageCount: _ctx.userLogs.total,
              paginationPageTotal: _ctx.userLogs.total,
              paginationLastPage: _ctx.userLogs.last_page,
              onPaginate: _ctx.paginateUser,
              paginationPosition: "center",
              backgroundColor: "#34bfa3",
              fontColor: "#fff"
            }, null, 8, ["columns", "items", "paginationPageSize", "paginationPageCount", "paginationPageTotal", "paginationLastPage", "onPaginate"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.user.role_id != 7)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_tableStats, {
              columns: _ctx.leadsColumn,
              items: _ctx.leadsNew.data,
              title: "New Leads",
              isBody: "",
              bodyTitle: "New",
              bodyValue: _ctx.leadsNew.total,
              isPagination: "",
              paginationPageSize: _ctx.leadsNew.per_page,
              paginationPageCount: _ctx.leadsNew.total,
              paginationPageTotal: _ctx.leadsNew.total,
              paginationLastPage: _ctx.leadsNew.last_page,
              onPaginate: _ctx.paginateLeadsNew,
              paginationPosition: "center",
              backgroundColor: "#34bfa3",
              fontColor: "#fff"
            }, null, 8, ["columns", "items", "bodyValue", "paginationPageSize", "paginationPageCount", "paginationPageTotal", "paginationLastPage", "onPaginate"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.user.role_id != 7)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_tableStats, {
              columns: _ctx.leadsColumn,
              items: _ctx.leadsPending.data,
              title: "Pending Leads",
              isBody: "",
              bodyTitle: "Pending",
              bodyValue: _ctx.leadsPending.total,
              isPagination: "",
              paginationPageSize: _ctx.leadsPending.per_page,
              paginationPageCount: _ctx.leadsPending.total,
              paginationPageTotal: _ctx.leadsPending.total,
              paginationLastPage: _ctx.leadsPending.last_page,
              onPaginate: _ctx.paginateLeadsPending,
              paginationPosition: "center",
              backgroundColor: "#34bfa3",
              fontColor: "#fff"
            }, null, 8, ["columns", "items", "bodyValue", "paginationPageSize", "paginationPageCount", "paginationPageTotal", "paginationLastPage", "onPaginate"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.user.role_id != 7)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_tableStats, {
              columns: _ctx.transactionsColumn,
              items: _ctx.transactionCodData,
              title: "COD Transactions",
              isBody: "",
              bodyTitle: "COD Txns",
              bodyValue: _ctx.transactionCod.total,
              isPagination: "",
              paginationPageSize: _ctx.transactionCod.per_page,
              paginationPageCount: _ctx.transactionCod.total,
              paginationPageTotal: _ctx.transactionCod.total,
              paginationLastPage: _ctx.transactionCod.last_page,
              onPaginate: _ctx.paginateCOD,
              paginationPosition: "center",
              backgroundColor: "#36a3f7",
              fontColor: "#fff"
            }, null, 8, ["columns", "items", "bodyValue", "paginationPageSize", "paginationPageCount", "paginationPageTotal", "paginationLastPage", "onPaginate"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.user.role_id != 7)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_tableStats, {
              columns: _ctx.transactionsColumn,
              items: _ctx.transactionOnlineData,
              title: "Online Transactions",
              isBody: "",
              bodyTitle: "Online Txns",
              bodyValue: _ctx.transactionOnline.total,
              isPagination: "",
              paginationPageSize: _ctx.transactionOnline.per_page,
              paginationPageCount: _ctx.transactionOnline.total,
              paginationPageTotal: _ctx.transactionOnline.total,
              paginationLastPage: _ctx.transactionOnline.last_page,
              onPaginate: _ctx.paginateOnline,
              paginationPosition: "center",
              backgroundColor: "#34bfa3",
              fontColor: "#fff"
            }, null, 8, ["columns", "items", "bodyValue", "paginationPageSize", "paginationPageCount", "paginationPageTotal", "paginationLastPage", "onPaginate"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_tableStats, {
          isTable: false,
          isBodyFields: "",
          BodyFields: _ctx.Comprehensive,
          title: "Policies Comprehensive",
          backgroundColor: "#34bfa3",
          fontColor: "#fff"
        }, null, 8, ["BodyFields"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_tableStats, {
          isTable: false,
          isBodyFields: "",
          BodyFields: _ctx.ThirdParty,
          title: "Policies Third Party",
          backgroundColor: "#34bfa3",
          fontColor: "#fff"
        }, null, 8, ["BodyFields"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_tableStats, {
          isTable: false,
          isBodyFields: "",
          BodyFields: _ctx.carValue,
          title: "Avg. Quoted Vehicle",
          backgroundColor: "#716aca",
          fontColor: "#fff"
        }, null, 8, ["BodyFields"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_tableStats, {
          isTable: false,
          isBodyFields: "",
          twoAndDown: "",
          hfont: "blue",
          lcolor: "red",
          BodyFields: _ctx.closingRatio,
          title: "Closing Ratio",
          backgroundColor: "#36a3f7",
          fontColor: "#fff"
        }, null, 8, ["BodyFields"])
      ]),
      (_ctx.user.role_id != 7)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_tableStats, {
              columns: _ctx.leadsColumn,
              items: _ctx.leadsLost.data,
              title: "Lost Leads",
              isBody: "",
              bodyTitle: "Lost",
              bodyValue: _ctx.leadsLost.total,
              isPagination: "",
              paginationPageSize: _ctx.leadsLost.per_page,
              paginationPageCount: _ctx.leadsLost.total,
              paginationPageTotal: _ctx.leadsLost.total,
              paginationLastPage: _ctx.leadsLost.last_page,
              onPaginate: _ctx.paginateLeadsLost,
              paginationPosition: "center",
              backgroundColor: "#34bfa3",
              fontColor: "#fff"
            }, null, 8, ["columns", "items", "bodyValue", "paginationPageSize", "paginationPageCount", "paginationPageTotal", "paginationLastPage", "onPaginate"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.user.role_id != 7)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_tableStats, {
              isTable: false,
              isBodyFields: "",
              BodyFields: _ctx.smsStat,
              title: "SMS Stat"
            }, null, 8, ["BodyFields"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}