
import { defineComponent, computed, ref } from "vue";
import Pie from "@/views/dashboard/PieChart.vue";
import { useStore } from "vuex";
import CustomerImportModal from "@/components/modals/forms/CustomerImportModal.vue";

export default defineComponent({
  components: {
    Pie,
    CustomerImportModal
  },

  setup() {
    const store = useStore();

    const isLead = computed(() => {
      return store.getters.getIsLeads;
    });

    const leadCount = computed(() => {
      return store.getters.getCountLeadSourceLeads;
    })

    const leadCountDeleted = computed(() => {
      return store.getters.getCountLeadSourceLeadsDeleted;
    })

    const dealCount = computed(() => {
      return store.getters.getCountLeadSourceDeals;
    })

    const paidVsOragnicLeads = computed(() => {
      return store.getters.getGraphPaidVsOrganicLeads;
    });

    const paidVsOragnicDeals = computed(() => {
      return store.getters.getGraphPaidVsOrganicDeals;
    });

    const organicUTMLeads = computed(() => {
      return store.getters.getGraphOrganicUTMLeads;
    });

    const organicUTMDeals = computed(() => {
      return store.getters.getGraphOrganicUTMDeals;
    });

    const paidUTMLeads = computed(() => {
      return store.getters.getGraphPaidUTMLeads;
    });

    const paidUTMDeals = computed(() => {
      return store.getters.getGraphPaidUTMDeals;
    });

    const leadSourceLeads = computed(() => {
      return store.getters.getGraphLeadSourceLeads;
    });

    const leadSourceDeals = computed(() => {
      return store.getters.getGraphLeadSourceDeals;
    });

    const utmCampaignLeads = computed(() => {
      return store.getters.getGraphPaidUTMCampaignLeads;
    });

    const utmCampaignDeals = computed(() => {
      return store.getters.getGraphPaidUTMCampaignDeals;
    });
    const utmCampaignLeadsDeleted = computed(() => {
      return store.getters.getGraphPaidUTMCampaignLeadsDeleted;
    });

    const paidVsOragnicWIPDeals = computed(() => {
      return store.getters.getGraphPaidVsOrganicWIPDeals;
    });

    const utmCampaignDealsWip = computed(() => {
      return store.getters.getGraphPaidUTMCampaignDealsWip;
    });

    const utmContentDealsWip = computed(() => {
      return store.getters.getGraphPaidUTMContentDealsWip;
    });

    const utmCampaignOrganicDealsWip = computed(() => {
      return store.getters.getGraphOrganicUTMCampaignDealsWip;
    });

    const utmContentOrganicDealsWip = computed(() => {
      return store.getters.getGraphOrganicUTMContentDealsWip;
    });

    const utmContentLeads = computed(() => {
      return store.getters.getGraphPaidUTMContentLeads;
    });

    const utmContentDeals = computed(() => {
      return store.getters.getGraphPaidUTMContentDeals;
    });

    const utmCampaignDealsRenewal = computed(() => {
      return store.getters.getGraphPaidUTMCampaignDealsRenewal;
    });

    const utmCampaignDealsLlr = computed(() => {
      return store.getters.getGraphPaidUTMCampaignDealsLlr;
    });

    const utmContentDealsRenewal = computed(() => {
      return store.getters.getGraphPaidUTMContentDealsRenewal;
    });

    const utmContentDealsLlr = computed(() => {
      return store.getters.getGraphPaidUTMContentDealsLlr;
    });

    const utmCampaignLeadsRenewal = computed(() => {
      return store.getters.getGraphPaidUTMCampaignLeadsRenewal;
    });

    const utmCampaignLeadsLlr = computed(() => {
      return store.getters.getGraphPaidUTMCampaignLeadsLlr;
    });

    const utmContentLeadsRenewal = computed(() => {
      return store.getters.getGraphPaidUTMContentLeadsRenewal;
    });

    const utmContentLeadsLlr = computed(() => {
      return store.getters.getGraphPaidUTMContentLeadsLlr;
    });

    return {
      utmCampaignLeadsRenewal,
      utmCampaignLeadsLlr,
      utmContentLeadsRenewal,
      utmContentDealsLlr,
      utmContentLeadsLlr,
      utmContentDealsRenewal,
      utmCampaignDealsLlr,
      utmCampaignDealsRenewal,
      utmContentDeals,
      utmContentLeads,
      utmContentOrganicDealsWip,
      utmCampaignOrganicDealsWip,
      utmContentDealsWip,
      utmCampaignDealsWip,
      paidVsOragnicWIPDeals,
      utmCampaignLeadsDeleted,
      leadCountDeleted,
      leadCount,
      dealCount,
      isLead,
      paidVsOragnicLeads,
      paidVsOragnicDeals,
      organicUTMLeads,
      organicUTMDeals,
      paidUTMLeads,
      paidUTMDeals,
      leadSourceLeads,
      leadSourceDeals,
      utmCampaignLeads,
      utmCampaignDeals,
    };
  }
});
