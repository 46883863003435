<template>
  <div class="container ps-2 pe-2">
    <div class="row">
      <draggable
        class="dragArea grid w-full"
        style="display: contents"
        @change="log"
      >
        <!-- New Leads Graphs Starts -->
        <div class="col-sm-4 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="paidVsOragnicLeads.options" :series="paidVsOragnicLeads.series"
          title="Paid vs Organic New Leads" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-4 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="paidUTMLeads.options" :series="paidUTMLeads.series" 
          title="Paid New Leads By UTM Source"
          backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-4 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="organicUTMLeads.options" :series="organicUTMLeads.series"
          title="Organic New Leads By UTM Source" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-6 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="utmCampaignLeads.options" :series="utmCampaignLeads.series"
          title="Paid New Leads By UTM Campaign" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-6 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="paidVsOragnicWIPDeals.options" :series="paidVsOragnicWIPDeals.series"
          title="Paid vs Organic WIP Deals" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        
        <div class="col-sm-6 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="paidVsOragnicDeals.options" :series="paidVsOragnicDeals.series"
          title="Paid vs Organic New Deals" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-6 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="utmCampaignDeals.options" :series="utmCampaignDeals.series"
          title="Paid New Deals By UTM Campaign" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-6 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="paidUTMDeals.options" :series="paidUTMDeals.series" 
          title="Paid New Deals By UTM Source"
          backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-6 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="organicUTMDeals.options" :series="organicUTMDeals.series"
          title="Organic New Deals By UTM Source" backgroundColor="#fff" fontColor="#181C32" />
        </div>

        <div class="col-sm-6 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="leadSourceLeads.options" :series="leadSourceLeads.series"
          title="New Leads By Lead Source" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-6 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="leadSourceDeals.options" :series="leadSourceDeals.series"
          title="New Deals By Lead Source" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <!-- New Leads Graphs Ends -->
        <!-- Total Leads Graphs Starts -->
        <!-- <div class="col-sm-4 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="paidVsOragnicLeadsTotal.options" :series="paidVsOragnicLeadsTotal.series"
          title="Paid vs Organic Total Leads" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-4 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="paidUTMLeadsTotal.options" :series="paidUTMLeadsTotal.series" 
          title="Paid Total Leads By UTM Source"
          backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-4 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="organicUTMLeadsTotal.options" :series="organicUTMLeadsTotal.series"
          title="Organic Total Leads By UTM Source" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-4 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="paidVsOragnicDealsTotal.options" :series="paidVsOragnicDealsTotal.series"
          title="Paid vs Organic Total Deals" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-4 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="paidUTMDealsTotal.options" :series="paidUTMDealsTotal.series" 
          title="Paid Total Deals By UTM Source"
          backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-4 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="organicUTMDealsTotal.options" :series="organicUTMDealsTotal.series"
          title="Organic Total Deals By UTM Source" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-6 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="leadSourceLeadsTotal.options" :series="leadSourceLeadsTotal.series"
          title="Total Leads By Lead Source" backgroundColor="#fff" fontColor="#181C32" />
        </div>
        <div class="col-sm-6 mb-6 p-1">
          <Pie v-if="isLead" :chartOptions="leadSourceDealsTotal.options" :series="leadSourceDealsTotal.series"
          title="Total Deals By Lead Source" backgroundColor="#fff" fontColor="#181C32" />
        </div> -->
        <!-- Total Leads Graphs Starts -->
        <!-- <div class="col-sm-6 mb-6 p-1" v-if="user.role_id != 7">
          <tableStats
            :columns="policyIssueColumn"
            :items="policyIssueStat"
            title="Policy Issue Stat"
            backgroundColor="#f4516c"
            fontColor="#fff"
          />
        </div>
        <div class="col-sm-6 mb-6 p-1" v-if="user.role_id != 7">
          <tableStats
            :columns="policyCreatorColumn"
            :items="policyCreatorStat"
            title="Policy Creator Stat"
            backgroundColor="#f4516c"
            fontColor="#fff"
          />
        </div> -->
        <div class="col-sm-6 mb-6 p-1">
          <tableStats
            :columns="topProviderColumns"
            :items="topProviders"
            title="Top Providers"
            showCount
            backgroundColor="#36a3f7"
            fontColor="#fff"
          />
        </div>
        <div class="col-sm-6 mb-6 p-1">
          <tableStats
            :columns="topNationalityColumns"
            :items="topNationalities"
            title="Top Nationality"
            showCount
            backgroundColor="#f4516c"
            fontColor="#fff"
          />
        </div>
        <div class="col-sm-6 mb-6 p-1">
          <tableStats
            :columns="topBrandColumns"
            :items="topBrands"
            title="Top 10 Brand Policies Sold"
            backgroundColor="#ffb822"
            fontColor="#fff"
          />
        </div>
        <div class="col-sm-6 mb-6 p-1">
          <tableStats
            :columns="topVehicleColumns"
            :items="topVehicles"
            title="Top 10 Vehicle Policies Sold"
            backgroundColor="#716aca"
            fontColor="#fff"
          />
        </div>
        <div class="col-sm-6 mb-6 p-1" v-if="user.role_id != 7">
          <tableStats
            :columns="discountSummaryColumn"
            :items="discountSummary"
            title="Discount Code Summary"
            backgroundColor="#34bfa3"
            fontColor="#fff"
          />
        </div>

        <div class="col-sm-6 mb-6 p-1">
          <tableStats
            :isTable="false"
            isBodyFields
            :BodyFields="policyCoupon"
            title="Policies sold w/o coupons"
            backgroundColor="#34bfa3"
            fontColor="#fff"
          />
        </div>
      </draggable>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref } from "vue";
import Pie from "@/views/dashboard/PieChart.vue";
import tableStats from "@/views/dashboard/TableStatistics.vue";
import DashboardService from "@/core/services/car/DashboardService";
import { useStore } from "vuex";
import moment from "moment";
import { VueDraggableNext } from "vue-draggable-next";

export default defineComponent({
  components: {
    Pie,
    tableStats,
    draggable: VueDraggableNext
  },

  props: {
    daterange: {
      type: Array,
      required: true
    }
  },

  setup(props) {
    const store = useStore();

    const drag = ref([]);

    const log = (e) => {
      return console.log(e);
    };

    const policyIssueColumn = ref([
      {
        label: "Agent",
        prop: "name",
        width: "250",
        align: "center"
      },
      {
        label: "Policy Issue Time",
        prop: "average_policy_time_hours",
        width: "250",
        align: "center"
      }
    ]);

    const policyCreatorColumn = ref([
      {
        label: "Policy Creator",
        prop: "name",
        width: "140",
        align: "center"
      },
      {
        label: "Policy Assigned",
        prop: "assigned",
        width: "140",
        align: "center"
      },
      {
        label: "Policy Issued",
        prop: "sold",
        width: "125",
        align: "center"
      },
      {
        label: "Avg Policy Issue time (hrs)",
        prop: "average_policy_time_hours",
        width: "140",
        align: "center"
      }
    ]);

    const topProviderColumns = ref([
      {
        label: "Provider Name",
        prop: "providers",
        width: "250",
        align: "center"
      },
      {
        label: "Total Policies Sold",
        prop: "sold",
        width: "250",
        align: "center"
      },
      {
        label: "Total Revenue ($)",
        prop: "revenue",
        width: "250",
        align: "center"
      }
    ]);

    const topNationalityColumns = ref([
      {
        label: "Country",
        prop: "countries",
        width: "250",
        align: "center"
      },
      {
        label: "Sold Policies",
        prop: "sold",
        width: "250",
        align: "center"
      }
    ]);

    const topBrandColumns = ref([
      {
        label: "Brand",
        prop: "brands",
        width: "250",
        align: "center"
      },
      {
        label: "Sold Policies",
        prop: "sold",
        width: "250",
        align: "center"
      }
    ]);

    const topVehicleColumns = ref([
      {
        label: "Manufacturer",
        prop: "brands",
        width: "200",
        align: "center"
      },
      {
        label: "Model",
        prop: "model",
        width: "200",
        align: "center"
      },
      {
        label: "Sold Policies",
        prop: "sold",
        width: "200",
        align: "center"
      }
    ]);

    const discountSummaryColumn = ref([
      {
        label: "Code",
        prop: "code",
        width: "200",
        align: "center"
      },
      {
        label: "Total Policies Sold",
        prop: "policies_sold",
        width: "200",
        align: "center"
      },
      {
        label: "Status",
        prop: "status",
        width: "200",
        align: "center"
      }
    ]);

    const paidVsOragnicLeads = computed(() => {
      return store.getters.getGraphPaidVsOrganicLeads;
    });

    const paidVsOragnicDeals = computed(() => {
      return store.getters.getGraphPaidVsOrganicDeals;
    });

    const organicUTMLeads = computed(() => {
      return store.getters.getGraphOrganicUTMLeads;
    });

    const organicUTMDeals = computed(() => {
      return store.getters.getGraphOrganicUTMDeals;
    });

    const paidUTMLeads = computed(() => {
      return store.getters.getGraphPaidUTMLeads;
    });

    const paidUTMDeals = computed(() => {
      return store.getters.getGraphPaidUTMDeals;
    });

    const leadSourceLeads = computed(() => {
      return store.getters.getGraphLeadSourceLeads;
    });

    const leadSourceDeals = computed(() => {
      return store.getters.getGraphLeadSourceDeals;
    });

    const paidVsOragnicLeadsTotal = computed(() => {
      return store.getters.getGraphPaidVsOrganicLeadsTotal;
    });

    const paidVsOragnicDealsTotal = computed(() => {
      return store.getters.getGraphPaidVsOrganicDealsTotal;
    });

    const paidVsOragnicWIPDeals = computed(() => {
      return store.getters.getGraphPaidVsOrganicWIPDeals;
    });

    const organicUTMLeadsTotal = computed(() => {
      return store.getters.getGraphOrganicUTMLeadsTotal;
    });

    const organicUTMDealsTotal = computed(() => {
      return store.getters.getGraphOrganicUTMDealsTotal;
    });

    const paidUTMLeadsTotal = computed(() => {
      return store.getters.getGraphPaidUTMLeadsTotal;
    });

    const paidUTMDealsTotal = computed(() => {
      return store.getters.getGraphPaidUTMDealsTotal;
    });

    const leadSourceLeadsTotal = computed(() => {
      return store.getters.getGraphLeadSourceLeadsTotal;
    });

    const leadSourceDealsTotal = computed(() => {
      return store.getters.getGraphLeadSourceDealsTotal;
    });

    const utmCampaignLeads = computed(() => {
      return store.getters.getGraphPaidUTMCampaignLeads;
    });

    const utmCampaignDeals = computed(() => {
      return store.getters.getGraphPaidUTMCampaignDeals;
    });

    const policyIssueStat = computed(() => {
      const datas = store.getters.getPolicyIssueStat;
      if (datas) {
        return datas.map((data) => {
          return {
            name: data.name,
            average_policy_time_hours: calculateDays(
              data.average_policy_time_hours
            )
          };
        });
      } else {
        return [];
      }
    });

    const policyCreatorStat = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      if (datas) {
        return datas.map((data) => {
          return {
            name: data.name,
            assigned: data.assigned,
            sold: data.sold,
            average_policy_time_hours: calculateDays(
              data.average_policy_time_hours
            )
          };
        });
      } else {
        return [];
      }
    });

    const isLead = computed(() => {
      return store.getters.getIsLeads;
    });

    const topProviders = computed(() => {
      return store.getters.getTopProviders;
    });

    const topNationalities = computed(() => {
      return store.getters.getTopNationalities;
    });

    const topBrands = computed(() => {
      return store.getters.getTopBrands;
    });

    const topVehicles = computed(() => {
      return store.getters.getTopVehicles;
    });

    const policyCoupon = computed(() => {
      const data = store.getters.getPoliciesCoupon;
      if (data) {
        return {
          "With Coupons": data.policies_sold_with_coupon,
          "Without Coupons": data.policies_sold_without_coupon
        };
      } else {
        return {};
      }
    });

    const discountSummary = computed(() => {
      const data = store.getters.getDiscountSummary;
      if (data) {
        return data.map((val) => {
          return {
            code: val.promotion_code,
            policies_sold: val.policies_sold,
            status: val.code_value_type == 1 ? "Active" : "Inactive"
          };
        });
      } else {
        return [];
      }
    });

    const calculateDays = (hours) => {
      return Math.floor(hours / 24) > 0
        ? Math.floor(hours / 24) + " days"
        : hours + " hours";
    };

    const user = computed(() => {
      return store.getters.currentUser;
    });

    return {
      user,
      drag,
      log,
      discountSummaryColumn,
      discountSummary,
      policyCoupon,
      topVehicleColumns,
      topBrandColumns,
      topNationalityColumns,
      topProviderColumns,
      policyIssueColumn,
      policyIssueStat,
      policyCreatorColumn,
      policyCreatorStat,
      topProviders,
      topVehicles,
      topNationalities,
      topBrands,
      isLead,
      paidVsOragnicLeads,
      paidVsOragnicDeals,
      organicUTMLeads,
      organicUTMDeals,
      paidUTMLeads,
      paidUTMDeals,
      leadSourceLeads,
      leadSourceDeals,
      paidVsOragnicLeadsTotal,
      paidVsOragnicDealsTotal,
      organicUTMLeadsTotal,
      organicUTMDealsTotal,
      paidUTMLeadsTotal,
      paidUTMDealsTotal,
      leadSourceLeadsTotal,
      leadSourceDealsTotal,
      utmCampaignLeads,
      utmCampaignDeals,
      paidVsOragnicWIPDeals,
    };
  }
});
</script>
