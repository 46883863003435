
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
  },

  setup() {
    const store = useStore();

    const assigned = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.assigned);
      }, 0);
    });

    const completed = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.completed);
      }, 0);
    });


    const cancellation = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.cancellation);
      }, 0);
    });

    const cancellationAmount = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.cancellation_amount);
      }, 0);
    });

    const partialCancellation = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.partial_cancellation);
      }, 0);
    });

    const partialCancellationAmount = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.partial_cancellation_amount);
      }, 0);
    });

    const fullCancellation = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.full_cancellation);
      }, 0);
    });

    const fullCancellationAmount = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.full_cancellation_amount);
      }, 0);
    });

    const overdue = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.overdue);
      }, 0);
    });

    const assigned_today = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.assigned_today);
      }, 0);
    });

    const assigned_this_month = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.assigned_this_month);
      }, 0);
    });

    const assigned_prev_months = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.assigned_prev_months);
      }, 0);
    });

    const assigned_in_month_until_yesterday = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.assigned_in_month_until_yesterday);
      }, 0);
    });

    const pending_quote_requests = computed(() => {
      const datas = store.getters.getPolicyCreatorStat;
      return datas.reduce((total, item) => {
        return (total += item.pending_quote_requests);
      }, 0);
    });

    return {
      cancellationAmount,
      partialCancellation,
      partialCancellationAmount,
      fullCancellationAmount,
      fullCancellation,
      assigned_in_month_until_yesterday,
      assigned,
      completed,
      cancellation,
      overdue,
      assigned_today,
      assigned_this_month,
      assigned_prev_months,
      pending_quote_requests,
    };
  }
});
