<template>
  <div class="card">
    <div
      class="card-header boder-0 pt-5"
      :style="`background-color: ${backgroundColor} !important`"
    >
      <h3 class="card-title align-items-start flex-column">
        <span
          class="card-label fw-bolder fs-3 mb-1"
          :style="`color: ${fontColor} !important`"
        >
          {{ title }}
          <span v-if="showCount" class="badge badge-primary bold uppercase"
            >Total: {{ items.length }}</span
          >
        </span>
      </h3>
    </div>
    <div class="card-body">
      <div v-if="isBody">
        <p>{{ bodyTitle }}</p>
        <h1 :style="`color: ${bodyValueColor}; font-size: ${bodyValueSize}`">
          {{ bodyValue }}
        </h1>
      </div>
      <div class="container" v-if="isBodyFields">
        <div class="row d-flex justify-content-center" v-if="!twoAndDown">
          <div
            :class="
              Object.keys(BodyFields).length == 3
                ? 'col-sm-4'
                : Object.keys(BodyFields).length == 5
                ? 'col-sm-2'
                : 'col-sm-3'
            "
            v-for="(item, index) in BodyFields"
            :key="index"
          >
            <p :style="`text-align: center; font-size: ${textSize}`">
              {{ index }}
            </p>
            <h2
              :style="`text-align: center; font-size: ${textValue}; color: green`"
            >
              {{ item }}
            </h2>
          </div>
        </div>
        <div class="row d-flex justify-content-center" v-else>
          <div
            class="col-sm-6"
            v-for="(item, index) in BodyFields"
            :key="index"
          >
            <p :style="`text-align: center; font-size: ${textSize}`">
              {{ index }}
            </p>
            <h3
              :style="`text-align: center; font-size: ${textValue}; color: blue`"
              v-if="index"
            >
              {{ item }}
            </h3>
            <h3
              style="text-align: center; font-size: 2.5rem; color: red"
              v-else
            >
              {{ item }}%
            </h3>
          </div>
        </div>
      </div>
      <modalTable
        v-if="isTable"
        :isIndex="false"
        :columns="columns"
        :dataTable="items"
        :isSummary="isSummary"
        :sumText="sumText"
      />
      <div>
        <el-pagination
                v-if="isPagination"
                v-show="paginationLastPage > 1"
                v-model:currentPage="currentPage"
                :page-size="parseInt(paginationPageSize)"
                :small="large"
                layout="prev, pager, next"
                :total="paginationPageTotal"
                @current-change="$emit('paginate', $event)"
                :style="`text-align: ${paginationPosition}`"
                />
        <!-- <el-pagination
          v-if="isPagination"
          v-show="paginationLastPage > 1"
          :page-size="paginationPageSize"
          :pager-count="paginationPageCount"
          layout="prev, pager, next"
          :total="paginationPageTotal"
          @current-change="$emit('paginate', $event)"
          :style="`text-align: ${paginationPosition}`"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import modalTable from "@/components/partials/Invoices/modalTable.vue";

export default defineComponent({
  props: {
    columns: [],
    items: [],
    title: String,
    showCount: {
      type: Boolean,
      required: false,
      default: false
    },
    isPagination: {
      type: Boolean,
      required: false,
      default: false
    },
    isTable: {
      type: Boolean,
      required: false,
      default: true
    },
    twoAndDown: {
      type: Boolean,
      required: false,
      default: false
    },
    paginationPageSize: {
      type: Number,
      required: false,
      default: 1
    },
    paginationPageCount: {
      type: Number,
      required: false,
      default: 5
    },
    paginationPageTotal: {
      type: Number,
      required: false,
      default: 1
    },
    paginationLastPage: {
      type: Number,
      required: false,
      default: 1
    },
    paginationPosition: {
      type: String,
      required: false,
      default: "left"
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#fff"
    },
    fontColor: {
      type: String,
      required: false,
      default: "#000"
    },
    isBody: {
      type: Boolean,
      required: false,
      default: false
    },
    isBodyFields: {
      type: Boolean,
      required: false,
      default: false
    },
    BodyFields: {
      Type: Array,
      required: false,
      default: []
    },
    bodyTitle: {
      type: String,
      required: false,
      default: "Trx"
    },
    bodyValue: {
      type: [String, Number],
      required: false,
      default: "0"
    },
    bodyValueColor: {
      type: String,
      required: false,
      default: "red"
    },
    bodyValueSize: {
      type: String,
      required: false,
      default: "1.75rem"
    },
    isSummary: {
      type: Boolean,
      required: false,
      value: false
    },
    sumText: {
      type: String,
      required: false,
      default: "Total"
    },
    textSize: {
      type: String,
      required: false,
      default: "12px"
    },
    textValue: {
      type: String,
      required: false,
      default: "20px"
    }
  },

  components: {
    modalTable
  }
});
</script>
