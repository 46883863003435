
import DashboardService from "@/core/services/car/DashboardService";
import tableStats from "@/views/dashboard/TableStatistics.vue";
import { defineComponent, ref, computed } from 'vue'
import { useStore } from "vuex";
import moment from "moment";
import { registration } from "@/store/stateless/store";

export default defineComponent({
    components: {
        tableStats
    },

    props: {
        daterange: {
            type: Array,
            required: true
        }
    },
    
    setup(props) {
        const store = useStore();

        const userLogs = computed(() => {
            return store.getters.getUserLogs
        })

        const leadsNew = computed(() => {
            return store.getters.getLeadsNew
        })

        const leadsPending = computed(() => {
            return store.getters.getLeadsPending
        })

        const leadsLost = computed(() => {
            return store.getters.getLeadsLost
        })

        const transactionCod = computed(() => {
        return store.getters.getTransactionCod
        })

        const transactionCodData = computed(() => {
        const data =  transactionCod.value.data
        if(data) {
            return data.map((val) => {
            return {
                merchant_reference: val.merchant_reference,
                policy_name: val.policy_name,
                amount: currency(val.amount)
            }
            })
        } else {
            return []
        }
        })

        const Comprehensive = computed(() => {
        const data = store.getters.getPolicyData
            if(data) {
                return {
                    "Total Amount": data.comprehensive_amount,
                    "Average Amount": data.comprehensive_amount_avg,
                    "Avareage Age": data.comprehensive_average_customer_age,
                    "Sold Policies": data.comprehensive_sold_count
                }
            } else {
                return {}
            }
        })

        const ThirdParty = computed(() => {
        const data = store.getters.getPolicyData
            if(data) {
                return {
                    "Total Amount": data.third_party_amount,
                    "Average Amount": data.third_party_amount_avg,
                    "Avareage Age": data.third_party_average_customer_age,
                    "Sold Policies": data.third_party_sold_count
                }
            } else {
                return {}
            }
        })

        const carValue = computed(() => {
        const data = store.getters.getQuotedVehicle
            if(data) {
                return {
                    "Car Value" : data.average_car_value
                }
            } else {
                return {}
            }
        })

        const closingRatio = computed(() => {
        const data = store.getters.getClosingRatio
            if(data) {
                return {
                    "Total Deals" : data.deals,
                    "Total Leads" : data.leads,
                    "": data.closing_ratio
                }
            } else {
                return {}
            }
        })

        const transactionDirect = computed(() => {
        return store.getters.getTransactionDirect
        })

        const transactionDirectData = computed(() => {
        const data =  transactionDirect.value.data
        if(data) {
            return data.map((val) => {
            return {
                merchant_reference: val.merchant_reference,
                policy_name: val.policy_name,
                amount: currency(val.amount)
            }
            })
        } else {
            return []
        }
        })

        const transactionOnline = computed(() => {
            return store.getters.getTransactionOnline
        })

        const transactionOnlineData = computed(() => {
        const data =  transactionOnline.value.data
        if(data) {
            return data.map((val) => {
            return {
                merchant_reference: val.merchant_reference,
                policy_name: val.policy_name,
                amount: currency(val.amount)
            }
            })
        } else {
            return []
        }
        })

        const smsStat = computed(() => {
        const data = store.getters.getSmsStat
            if(data) {
                return {
                    "Sent" : data.total_sent,
                }
            } else {
                return {}
            }
        })

        const leadsColumn = ref([
        {
            label: "Name",
            prop: "customer.name",
            width: "300",
            align: "center"
        },
        {
            label: "Email",
            prop: "customer.email",
            width: "300",
            align: "center"
        },
        {
            label: "Mobile",
            prop: "customer.phone_number",
            width: "300",
            align: "center"
        },
        {
            label: "Joined On",
            prop: "customer.created_at",
            width: "300",
            align: "center"
        },
        ])

        const topUserLogsColumns = ref([
        {
            label: 'Name',
            prop: 'name',
            width: '300',
            align: 'center'
        },
        {
            label: 'Login Date & Time',
            prop: 'recent_user_log.login_date_time',
            width: '300',
            align: 'center'
        },
        {
            label: 'IP Address',
            prop: 'recent_user_log.ip_address',
            width: '300',
            align: 'center'
        },
        {
            label: 'Browser',
            prop: 'recent_user_log.browser_type',
            width: '150',
            align: 'center'
        },
        {
            label: 'Lat',
            prop: 'recent_user_log.location.latitude',
            width: '150',
            align: 'center'
        },
        {
            label: 'Long',
            prop: 'recent_user_log.location.longitude',
            width: '150',
            align: 'center'
        },
        {
            label: 'Status',
            prop: 'user_status',
            width: '150',
            align: 'center'
        },
        ])

        const transactionsColumn = ref([
        {
            label: "Quote Ref No",
            prop: "merchant_reference",
            width: "200",
            align: "center"
        },
        {
            label: "Policy",
            prop: "policy_name",
            width: "200",
            align: "center"
        },
        {
            label: "Policy Price",
            prop: "amount",
            width: "200",
            align: "center"
        },
        ])

        

        const paginateLeadsNew = (e) => {
            let payloads = convertDates(props.daterange);
            payloads['page'] = e;
            DashboardService.getLeadsNew(payloads)
        }

        const paginateLeadsLost = (e) => {
            let payloads = convertDates(props.daterange);
            payloads['page'] = e;
            DashboardService.getLeadsLost(payloads)
        }

        const paginateUser = (e) => {
            let payloads = convertDates(props.daterange);
            payloads['page'] = e;
            DashboardService.getUserLogs(payloads)
        }

        const paginateLeadsPending = (e) => {
            let payloads = convertDates(props.daterange);
            payloads['page'] = e;
            DashboardService.getLeadsPending(payloads)
        }

         const paginateCOD = (e) => {
            let payloads = convertDates(props.daterange);
            payloads['page'] = e;
            DashboardService.getCOD(payloads)
        }

        const paginateOnline = (e) => {
            let payloads = convertDates(props.daterange);
            payloads['page'] = e;
            DashboardService.getOnline(payloads)
        }

        const paginateDirect = (e) => {
            let payloads = convertDates(props.daterange);
            payloads['page'] = e;
            DashboardService.getDirect(payloads)
        }

        const convertDates = (dates) => {
            const startDate = dates[0];
            const endDate = dates[1]

            return {
                start_date: moment(startDate).format("YYYY-MM-DD"),
                end_date: moment(endDate).format("YYYY-MM-DD")
            }
        }

        const currency = (e) => {
        const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: registration.currency,
            });

            return formatter.format(e);
        }

        const user = computed(() => {
            return store.getters.currentUser
        })

        return {
            registration,
            user,
            paginateCOD,
            paginateOnline,
            paginateDirect,
            paginateLeadsNew,
            paginateLeadsLost,
            paginateLeadsPending,
            paginateUser,
            smsStat,
            leadsLost,
            leadsPending,
            leadsNew,
            transactionCod,
            transactionCodData,
            transactionDirect,
            transactionDirectData,
            transactionOnline,
            transactionOnlineData,
            userLogs,
            Comprehensive,
            ThirdParty,
            carValue,
            closingRatio,
            leadsColumn,
            transactionsColumn,
            topUserLogsColumns
        }
    },
})
