
import { computed, defineComponent, onMounted } from "vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import CustomerImportModal from "@/components/modals/forms/CustomerImportModal.vue";
import moment from "moment";
import router from "@/router";
import { registration } from "@/store/stateless/store";


export default defineComponent({
  name: "overview",
  components: { CustomerImportModal },
  props: {
    textColor: String,
    color: String,
    title: String,
    data: Object,
    cardClass: {
      type: Array,
      required: true
    },
    iconClass: {
      type: Array,
      required: true
    },
    totalSales: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    totalLeads: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    closingRatio: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    compDeal: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    totalRevenue: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    untouchedCustomer: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    untouchedRenewalLeads: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    untouchedNewLeads: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    untouchedLlr: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    wipRhl: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    untouchedRhl: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    totalRefund: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    totalRefundAmount: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    partialRefund: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    partialRefundAmount: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    totalCancel: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    totalCancelAmount: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    partialCancel: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    partialCancelAmount: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    addon: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    cancelled: {
      type: String,
      required: false,
      default: "#5E6278"
    },
    daterange: {
      type: String,
      required: false,
      default: ""
    },
    lostLeadDeal: {
      type: String,
      required: false,
      default: "#5E6278"
    },
  },
  setup(_) {
    onMounted(() => {
      console.log(getTextWidth("9,242,480,286.71", ""));
    });

    const CompThird = computed(() => {
      return `${_.data?.comp_deal}/${_.data?.tpl_deal}`;
    });

    const HLRHL = computed(() => {
      return `${_.data?.untouched_hot_leads}/${_.data?.untouched_renewal_hot_leads}`;
    });

    const closingRatios = computed(() => {
      return `${_.data?.closing_ratio}%`;
    });

    const totalRefundCount = computed(() => {
      return _.data?.count_partial_refunds + _.data?.count_full_refunds;
    });

    const totalAmount = computed(() => {
      return `${totalRefundCount.value} | ${_.data?.total_commision_paid}`;
    });

    const getTextWidth = (text, font) => {
      const canvas = document.createElement("canvas");
      const context: any = canvas.getContext("2d");

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    };

    const currency = (e) => {
      const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: registration.currency,
          maximumFractionDigits: 2,
      });

      return formatter.format(e).replace(registration.currency, "");
    }

    const customNumberFormat = (value) => {
      return value ? value.toLocaleString() : 0;
    }
    
    const redirectToDashboardDetailsReport = (category,type, daterange) => {
    
      if(category == 'leads')
      {
        type = type.replace('sales','leads');
      }

      if(type == 'total-lrl-sales')
      {
        category = 'leads';
        type = 'total-lost-leads';
      }

      let dates = convertDates(daterange);
      if((category == 'sales' || category == 'leads') && type == 'lifetime-renewal-leads')
      {
        const startDate =  moment(daterange[0]).format("YYYY")+'-01-01';
        const endDate = daterange[1]; 

        dates = "?start_date="+startDate+"&end_date="+moment(endDate).format("YYYY-MM-DD");

      }
      // if(category == 'leads' && type == 'lifetime-renewal-leads')
      // {
      //   const startDate =  moment(daterange[0]).subtract(1,'years').format("YYYY")+'-01-01';
      //   const endDate = moment(daterange[0]).subtract(1,'years').format("YYYY")+'-12-31';; 

      //   dates = "?start_date="+startDate+"&end_date="+moment(endDate).format("YYYY-MM-DD");

      // }
    
      const queryString = dates+"&"+category+"="+type;

      let routerData = router.resolve({name: 'dashboard-details-report'})
      window.open(routerData.href+queryString, '_blank');

    }

    const convertDates = (dates) => {
      const startDate = dates[0];
      const endDate = dates[1];

      const queryString = "?start_date="+moment(startDate).format("YYYY-MM-DD")+"&end_date="+moment(endDate).format("YYYY-MM-DD");
      return queryString;
      

    };

    return {
      registration,
      convertDates,
      redirectToDashboardDetailsReport,
      getTextWidth,
      CompThird,
      closingRatios,
      HLRHL,
      totalAmount,
      totalRefundCount,
      currency,
      customNumberFormat,
    };
  }
});
